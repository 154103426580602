<template>
  <div id="cbtList">
    <h1>Your CBT Exams List</h1>
    <div id="cbtListBox">
      <!-- {{listCBTMurid}} -->
      <!-- {{loadingRefresh}} -->
      <a-alert v-if="!isOnSEB" message="Warning"
        description="Besides PH and PPDB, you can only take exam if you open this web on Safe Exam Browser!"
        type="warning" show-icon />
      <br />
      <TableCBTExamsList :loadingRefresh="loadingRefresh" @changeLoadingRefresh="changeLoadingRefresh" />
    </div>
  </div>
</template>
<script>
import TableCBTExamsList from '@/components/app/TableCBTExamsList'
// import moment from 'moment'
export default {
  components: {
    TableCBTExamsList,
  },
  data() {
    return {
      isFetching: true,
      loadingRefresh: false,
    }
  },
  computed: {
    isOnSEB() {
      return this.$store.state.isOnSEB
    },
    user() {
      return this.$store.state.user.user
    },
  },
  methods: {
    changeLoadingRefresh(payload) {
      this.loadingRefresh = payload
    },
  },
}
</script>
<style lang="scss">
#cbtList {
  #cbtListBox {
    margin-top: 4%;
    width: 100%;
    // min-height: 80vh;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0px 0px 10px #0419301A;
    padding: 5% 3%;
  }
}
</style>
